@import "@vmf/components/src/assets/style/_index.scss";




















































































































.decoration-wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    > .ctrl {
        /*position: fixed;*/
        /*right: 18px;*/
        /*width: 400px;*/
    }
}
