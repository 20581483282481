@import "@vmf/components/src/assets/style/_index.scss";







































































































































































.distributor-subordinate-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    > .header {
        flex: 0 0 auto;
    }
    > .body {
        flex: 1 1 1%;
    }
}
